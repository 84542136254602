.main {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 70px);
    color: black;
    box-sizing: border-box;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #c5d2ff;

        .content-detail {
            display: flex;
            justify-content: center;
            width: 100%;
            height: auto;
            // min-height: 700px;
            flex: 1;
            padding: 40px 0;

            .creatAccount {
                display: flex;
                flex-direction: column;
                width: 800px;
                height: 700px;

                .logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    font-size: 50px;
                    font-family: 'Bungee Spice';
                }

                .signupContent {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: calc(100% - 50px);
                    border: 1px solid #bbb;
                    margin-top: 30px;
                    border-radius: 10px;
                    background-color: white;

                    .logoTitle {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 70px;
                        font-size: 35px;
                        box-sizing: border-box;
                        padding: 2% 5%;
                    }

                    .creatAccount_signupItem {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 50px;
                        box-sizing: border-box;
                        padding-left: 5%;
                        margin-top: 10px;

                        .text {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 30%;
                            height: 100%;
                            font-weight: 500;
                            font-size: 20px;
                            box-sizing: border-box;
                            padding-left: 10px;

                            .alert {
                                display: flex;
                                align-items: flex-end;
                                color: red;
                                font-size: 12px;
                                height: 100%;
                            }

                            .link {
                                margin-left: 20px;
                            }
                        }

                        .input {
                            width: 50%;
                            height: 50%;
                            font-size: 20px;
                        }

                        button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 350px;
                            height: 55px;
                            box-sizing: border-box;
                            background-color: red;
                            border: none;
                            color: white;
                            font-size: 23px;
                            border-radius: 10px;
                            cursor: pointer;
                        }

                        button:hover {
                            transform: 0.25s;
                            font-size: 27px;
                        }

                        .line {
                            border: none; /* 移除默认边框 */
                            width: 90%;
                            height: 1px;
                            background-color: #bbb;
                            margin: 10px 0px;
                        }
                    }

                }
            }
        }
    }
}