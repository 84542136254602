.main {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
    color: black;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #c5d2ff;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 1500px;
        height: auto;
        min-height: calc(100% - 100px);
        box-sizing: border-box;
        padding: 40px 20px;
        flex: 1;

        .title {
            display: flex;
            width: 100%;
            height: 80px;
            font-weight: bolder;
            font-size: 30px;
        }

        .table {
            display: table;
            width: 100%;
            height: auto;
            min-height: 80px;
            box-sizing: border-box;
            border: none;
            background-color: white;
            padding: 20px 20px;
            margin-bottom: 50px;

            .tbody {

                th {
                    width: 150px;
                    height: 30px;
                    text-align: right;
                    vertical-align: top;
                    padding: 0 20px;
                }

                td {
                    padding: 0 40px;
                    height: 30px;
                    box-sizing: border-box;
                    vertical-align: top;
                    padding: 0 20px;
                }

                .tableTitle {
                    width: 100%;
                }
            }
        }

        .block {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            min-height: 80px;
            box-sizing: border-box;
            border: none;
            background-color: white;
            padding: 20px 20px;
            margin-bottom: 50px;

            .block-item {
                display: flex;
                width: 100%;
                height: auto;

                .item-detail {
                    display: flex;
                    align-items: center;
                    height: 50px;
                    width: auto;
                    margin-right: 50px;

                    .Creditcard_number {
                        // margin: 0;
                        width: 100px;
                        height: 28px;
                        margin-left: 10px;
                        font-size: 16px;
                        box-sizing: border-box;

                        &:focus {
                            box-shadow: none;
                            background-color: rgba(197, 210, 255, 0.5);
                        }

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0; /* Removes margin in Webkit browsers */
                        }

                    }

                    input[type="radio"] {
                        width: 20px;
                        height: 20px;
                        cursor: pointer;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    span {
                        margin-left: 10px;
                        font-size: 16px;
                    }

                    .dropdown {
                        height: 27px;
                        width: 100px;
                        color: black;
                        background-color: white;
                        margin-left: 20px;
                        font-size: 16px;
                    }

                    .checkout {
                        width: 180px;
                        height: 40px;
                        font-size: 20px;
                        border: none;
                        background-color: #3D52A0;
                        color: white;
                        cursor: pointer;
                        border-radius: 10px;

                        &:hover {
                            filter: brightness((1.3))
                        }
                    }
                }
            }
        }
    }
}