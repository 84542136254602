.main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    min-height: 100px;
    background-color: black;
    box-sizing: border-box;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        min-width: 1000px;
        height: 100%;

        .item-list {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;

            a {
                color: white;
                text-decoration: none;
                background-color: transparent;
            }

            a:hover {
                color: red;
            }

            span {
                margin: 0 10px;
            }
        }
    }
}