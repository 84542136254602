.main {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 70px);
    color: black;
    box-sizing: border-box;
    background-color: #c5d2ff;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .content-detail{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: auto;
            padding: 40px 0;
            // min-height: 700px;
            flex: 1;

            .loginMain {
                display: flex;
                flex-direction: column;
                width: 500px;
                height: 700px;

                .logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    font-size: 50px;
                    font-family: 'Bungee Spice';
                }

                .loginContent {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: calc(100% - 50px);
                    border: 1px solid #bbb;
                    margin-top: 30px;
                    border-radius: 10px;
                    background-color: white;

                    .logoTitle {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 70px;
                        font-size: 35px;
                        box-sizing: border-box;
                        padding: 2% 5%;
                    }

                    .loginItem {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100px;
                        box-sizing: border-box;
                        padding: 2% 5%;

                        .text {
                            display: flex;
                            width: 100%;
                            height: 50%;
                            margin-bottom: 20px;
                            font-weight: 500;
                            font-size: 20px;

                            .alert {
                                display: flex;
                                align-items: flex-end;
                                color: red;
                                margin-left: 10px;
                                font-size: 12px;
                                height: 100%;
                            }
                        }

                        .input {
                            width: 100%;
                            height: 50%;
                            font-size: 20px;
                        }

                        .login {
                            width: 50%;
                            height: 40px;
                            background-color: red;
                            color: white;
                            font-size: 20px;
                            border-radius: 10px;
                            cursor: pointer;
                            border: none;
                        }

                        .login:hover {
                            transition: 0.1s;
                            font-size: 25px;
                        }

                        .signup {
                            background-color: black;
                        }

                        .forgetPassword {
                            display: flex;
                            align-items: flex-end;
                            height: 40px;
                            margin-left: 20px;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    .line {
                        position: absolute;
                        top: 20px;
                        border: none; /* 移除默认边框 */
                        width: 90%;
                        height: 1px;
                        background-color: #bbb;
                        margin: 10px 0px;
                    }

                    .new {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 5px 20px;
                        background-color: transparent;
                        font-size: 20px;

                        label {
                            background-color: white;
                            box-sizing: border-box;
                            padding: 0 20px;
                            z-index:10;
                        }
                    }
                }
            }

            .profile-content {
                display: flex;
                flex-direction: column;
                width: 1000px;
                height: auto;
                margin-top: 20px;
        
                .title {
                    width: 100%;
                    height: 40px;
                    font-size: 30px;
                    box-sizing: border-box;
                    margin-bottom: 20px;
                    padding: 0 2%;
                }
        
                .content-table {
                    display: flex;
                    justify-content: space-around;
                    width: 100%;
                    height: 120px;
                    margin-bottom: 20px;
        
                    .content-item {
                        display: flex;
                        position: relative;
                        width: 300px;
                        height: 100%;
                        border: 1px #bbb solid;
                        border-radius: 10px;
                        cursor: pointer;
                        box-sizing: border-box;
                        padding: 1% 1%;
        
                        .item-img {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20%;
                            height: 100%;
                            font-size: 50px;
                        }
        
                        .text {
                            display: flex;
                            flex-direction: column;
                            width: 80%;
                            height: 100%;
                            box-sizing: border-box;
                            padding: 0 3%;
        
                            .item-title {
                                display: flex;
                                align-items: center;
                                height: 30%;
                                width: 100%;
                                font-size: 25px;
                            }
        
                            .item-dsc {
                                display: flex;
                                height: 70%;
                                width: 100%;
                                font-size: 14px;
                                box-sizing: border-box;
                                padding: 4% 2%;
                            }
                        }
                    }
        
                    .content-item:hover {
                        background-color: #c5d2ff;
                    }
                }
            }
        }
    }
}