.main {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
    color: black;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #c5d2ff;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        .cart-Content {
            // display: flex;
            // flex-direction: column;
            width: 1500px;
            height: auto;
            // min-height: 730px;
            // min-height: calc(100% - 100px);
            box-sizing: border-box;
            padding: 30px 0;
            flex: 1;

            .ListContent {
                width: 100%;
                height: auto;
                // border: 1px solid gray;
                border-radius: 20px;
                background-color: white;
                padding: 20px 100px;

                .title {
                    display: flex;
                    width: 100%;
                    height: 100px;
                    box-sizing: border-box;
                    font-size: 50px;
                    border-bottom: 1px solid #999;
                }

                .cartItem {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 200px;
                    box-sizing: border-box;

                    .cartItem-content {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        border-bottom: 1px solid #999;

                        .product-img {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30%;
                            height: 100%;
                            box-sizing: border-box;
                            padding: 20px 20px;

                            img {
                                max-width: 100%;
                                max-height: 100%;;
                            }
                        }

                        .product-detail {
                            display: flex;
                            // flex-direction: column;
                            width: 70%;
                            height: 100%;
                            padding: 20px 20px;
                            box-sizing: border-box;

                            .product-name {
                                width: 40%;
                                height: 100%;
                                font-size: 20px;
                            }

                            .product-namber {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 30%;
                                height: 100%;
                                box-sizing: border-box;
                                font-size: 20px;

                                .quantity {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 60%;
                                    height: 30%;
                                }
                            }

                            .product-price {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 30%;
                                height: 100%;
                                font-size: 20px;
                                color: red;
                            }
                        }
                    }
                }

                .total-price {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 100%;
                    height: 150px;
                    box-sizing: border-box;

                    .priceText {
                        display: flex;
                        align-items: flex-end;
                        width: 150px;
                        height: 50px;
                        box-sizing: border-box;
                        font-size: 20px;
                        margin-left: 20px;
                    }

                    .price {
                        display: flex;
                        align-items: center;
                        width: 150px;
                        height: 50px;
                        color: red;
                        font-size: 40px;
                    }
                    

                    .checkout {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 20px;
                        border-radius: 10px;
                        min-width: 150px;
                        color: white;
                        background-color: red;
                        border: none;
                        font-size: 22px;
                        cursor: pointer;
                        text-decoration: none;
                    }

                    .checkout:hover {
                        background-color: rgb(200, 8, 8);
                    }
                }
            }
        }
    }

    .empty-Content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        flex: 1;

        .cart-Empty-Content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1500px;
            height: calc(100% - 100px);
            font-size: 30px;

            .empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding: 30px 0;

                img {
                    object-fit: contain;
                    max-width: 70%;
                    max-height: 70%;
                    margin-bottom: 30px;
                }
            }
        }
    }
}