.productBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 370px;
    background-color: white;
    box-sizing: border-box;
    padding: 20px 20px;
    margin-bottom: 50px;
    border-radius: 10px;

    .arrow {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50px;
        z-index:10;
        font-size: 20px;

        .arrow-block {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            cursor: pointer;
            // border: 1px solid #333;
            box-shadow: 0px 1px 8px 2px #ccc;
            background-color: white;
            border-radius: 10px;
            box-sizing: border-box;
            color: #ccc;
        }

        .arrow-block:hover {
            box-shadow: 0px 1px 8px 2px rgb(0, 140, 255);
            color: rgb(0, 140, 255)
        }
    }

    .title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        font-size: 30px;
        font-weight: 500;
    }

    .line {
        border: none; /* 移除默认边框 */
        width: 100%;
        height: 1px;
        background-color: #bbb;
        margin: 20px 0px;
    }

    .block-content {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 10px 20px;
        scroll-behavior: smooth;

        .block-content-item {
            display: flex;
            flex-direction: column;
            width: 200px;
            min-width: 200px;
            height: 100%;
            // background-color: red;
            margin-right: 30px;
            cursor: pointer;
            box-sizing: border-box;

            .itemImg {
                display: flex;
                justify-content: center;
                align-items: center;
                height:60%;
                width: 100%;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .itemName {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 20%;
                font-weight: 500;
            }

            .itemPrice {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 20%;
                color: red;
                font-weight: 500;
            }
        }
    }
}