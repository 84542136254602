.main {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 70px);
    color: black;
    box-sizing: border-box;
    padding: 2% 0;

    .creatAccount {
        display: flex;
        flex-direction: column;
        width: 500px;
        height: 380px;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
            font-size: 50px;
            font-family: 'Bungee Spice';
        }

        .forgetpasswordContent {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100% - 50px);
            border: 1px solid #bbb;
            margin-top: 30px;
            border-radius: 10px;

            .logoTitle {
                display: flex;
                align-items: center;
                width: 100%;
                height: 70px;
                font-size: 35px;
                box-sizing: border-box;
                padding: 2% 5%;
            }

            .forgetItem {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100px;
                box-sizing: border-box;
                padding: 2% 5%;

                .text {
                    display: flex;
                    width: 100%;
                    height: 50%;
                    margin-bottom: 20px;
                    font-weight: 500;
                    font-size: 20px;

                    .alert {
                        display: flex;
                        align-items: flex-end;
                        color: red;
                        margin-left: 10px;
                        font-size: 12px;
                        height: 100%;
                    }
                }

                .input {
                    width: 100%;
                    height: 50%;
                    font-size: 20px;
                }

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    box-sizing: border-box;
                    background-color: red;
                    border: none;
                    color: white;
                    font-size: 23px;
                    border-radius: 10px;
                    cursor: pointer;
                }

                button:hover {
                    transform: 0.25s;
                    font-size: 27px;
                }
            }
        }

    }
}