.main {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
    color: black;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #c5d2ff;

    .main-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 1500px;
        height: auto;
        // min-height: 730px;
        box-sizing: border-box;
        padding: 30px 0;
        flex: 1;

        .productContent {
            position: relative;
            display: flex;
            width: 100%;
            height: auto;
            background-color: white;

            .product-img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40%;
                height: 700px;
                background-color: white;
                box-sizing: border-box;
                padding: 20px 20px;

                img {
                    object-fit: cover;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .productNav {
                display: flex;
                flex-direction: column;
                width: 60%;
                height: 700px;
                // background-color: blue;
                box-sizing: border-box;
                padding: 20px 20px;

                .productName {
                    display: flex;
                    align-items: center;
                    font-size:40px;
                    width: 100%;
                    height: 60px;
                    font-weight: bolder;
                }

                .productPrice {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    color: red;
                    font-size: 30px;
                    font-weight: bolder;
                    box-sizing: border-box;
                    padding: 5px 30px;
                    background-color: #eee;
                    border-radius: 10px;
                }

                .productNav-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 100px;

                    .buyList {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        width: 500px;
                        height: 50px;
                        box-sizing: border-box;

                        button {
                            min-width: 200px;
                            border: none;
                            cursor: pointer;
                            background-color: #ffa41c;
                            box-sizing: border-box;
                            padding: 10px 20px;
                            cursor: pointer;
                            font-size: 20px;
                            color: black;
                            border-radius: 30px;
                            margin-left: 30px;
                        }

                        button:hover {
                            filter: brightness(.9);
                        }
                    }

                    .quanity {
                        height: 50px;
                        width: 200px;
                    }

                    .inventory {
                        height: 40px;
                        width: 100px;
                        // background-color: #eee;;
                        border-radius: 10px;
                        // box-sizing: border-box;
                        // padding: 0 20px;
                        // border: 1px solid black;
                        color: black;
                    }
                }
            }
        }

        .productTitle {
            flex-direction: row;
        }

        .productBody {
            flex-direction: column;
        }
    }
}

.dialogContent {
    color: red;
}