.main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: inherit;

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 100%;
        font-size: 15px;
        cursor: pointer;
        box-sizing: border-box;
        padding: 10px 10px;
        background-color: #c5d2ff;
    }

    .button:hover {
        // background-color: #ddd;
        box-shadow: 0px 1px 8px 2px rgb(0, 140, 255);
    }

    .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        height: 100%;
        font-size: 30px;
    }
}