html, body, #root {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

.App {
	width: 100%;
	height: 100%;
}