.main {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(100, 100, 100, 0.5);
    z-index:999;

    .main-content {
        position: relative;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 550px;
        // height: 250px;
        height: auto;
        background-color: #c5d2ff;
        box-sizing: border-box;
        padding: 20px 20px;
        border-radius: 20px;

        .text {
            box-sizing: border-box;
            padding: 20px 0px;
            font-size: 20px;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            padding: 10px 10px;
            min-width: 100px;
            cursor: pointer;
            background-color: #888;
            color: white;
            border-radius: 10px;
        }

        button:hover {
            box-shadow: 0px 1px 8px 2px rgb(0, 140, 255);
        }
    }
}