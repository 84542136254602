.main {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
    color: black;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #c5d2ff;

    .main-content {
        display: flex;
        flex-direction: column;
        width: 1500px;
        height: auto;
        // min-height: 730px;
        box-sizing: border-box;
        padding: 30px 0;
        flex: 1;

        .product-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .product {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 300px;
                height: 500px;
                box-sizing: border-box;
                padding: 20px 20px;
                // background-color: red;

                .product-detail {
                    border: 1px solid #bbb;
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    box-sizing: border-box;
                    padding: 10px 10px;
                    cursor: pointer;
                    color: black;
                    text-decoration: none;
                    background-color: white;
                    border-color: transparent;

                    .product-detail-image {
                        display: flex;
                        align-content: center;
                        justify-content: center;
                        box-sizing: border-box;
                        padding: 10px 10px;
                        width: 100%;
                        height: 65%;
                        // background-color: red;

                        img {
                            object-fit: contain;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .product-detail-name {
                        display: flex;
                        align-content: center;
                        box-sizing: border-box;
                        padding: 10px 10px;
                        width: 100%;
                        height: 15%;
                        font-size: 20px;
                        // background-color: yellow;
                    }

                    .product-detail-price {
                        display: flex;
                        align-content: center;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 10px 10px;
                        width: 100%;
                        height: 10%;
                        color: red;
                        // background-color: green;
                        font-size: 20px;
                        font-weight: 500;

                        .CartShopping {
                            display: flex;
                            align-items: center;
                            height: 30px;
                            width: 30px;
                            color: black;
                            font-size: 25px;
                        }

                        .CartShopping:hover {
                            color: #777;
                        }
                    }

                    .product-detail-label {
                        display: flex;
                        align-content: center;
                        box-sizing: border-box;
                        padding: 10px 10px;
                        width: 100%;
                        height: 10%;
                        // background-color: blue;
                    }
                }

                .product-detail:hover {
                    border-color: red;

                    .product-detail-name {
                        color: red
                    }
                }
            }
        }
    }
}

.dialogContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 40px 0;
    font-size: 20px;
}