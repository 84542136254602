.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #c5d2ff;

    .main-content {
        position: relative;
        display: flex;
        justify-content: center;
        width: 1500px;
        height: auto;
        box-sizing: border-box;
        padding: 30px 0;

        .backgroundImg {
            top: 0px;
            left: 0px;
            width: 100%;
            z-index: -1;
        }

        .content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 1500px;
            height: auto;

            .content-header {
                position: relative;
                width: 100%;
                height: 550px;

                .news {
                    position: absolute;
                    right: 100px;
                    width: 1000px;
                    height: 250px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .ad {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: absolute;
                    top: 250px;
                    right: 100px;
                    width: 1000px;
                    // background-color: yellow;
                    height: 300px;

                    .ad-img {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 4% 0;
                        box-sizing: border-box;
                        height: 100%;

                        img {
                            // object-fit:cover
                            height: 100%;
                            cursor: pointer;
                        }
                    }

                    .ad-img:hover {
                        filter: brightness(1.2)
                    }
                }

                .categoryList {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    position: absolute;
                    left: 100px;
                    width: 250px;
                    height: 500px;
                    background-color: #3D52A0;
                    font-family: 'Aldrich';
                    border-radius: 20px;
                    box-sizing: border-box;
                    padding: 0 10px;
                    // border: 2px solid ;

                    .categoryTitle {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 50px;
                        color: white;
                        box-sizing: border-box;
                        padding: 10px 10px;
                        cursor: pointer;
                        font-size: 23px;
                    }

                    .categoryItem {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 50px;
                        color: white;
                        box-sizing: border-box;
                        padding: 10px 10px;
                        cursor: pointer;
                        text-decoration: none;
                        justify-content: center;
                        font-size: 23px;
                        background-color: white;
                        border-radius: 20px;
                        color: black;
                        transition: 0.25s;
                        font-weight: 500;

                        &:hover {
                            // box-shadow: 0px 1px 8px 2px #7091E6;
                            font-size: 30px;
                        }
                    }

                    .categoryItem:hover {
                        background-color: white;
                        color: black;
                    }
                }
            }

            .productList {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;
                box-sizing: border-box;
                padding: 0 100px;

                .productBlock {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 370px;
                    background-color: white;
                    box-sizing: border-box;
                    padding: 20px 20px;
                    margin-bottom: 50px;
                    border-radius: 10px;

                    .title {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 50px;
                        font-size: 30px;
                        font-weight: 500;
                    }

                    .line {
                        border: none; /* 移除默认边框 */
                        width: 100%;
                        height: 1px;
                        background-color: #bbb;
                        margin: 20px 0px;
                    }

                    .block-content {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        overflow-x: auto;
                        overflow-y: hidden;
                        box-sizing: border-box;
                        padding: 10px 20px;

                        .block-content-item {
                            display: flex;
                            flex-direction: column;
                            width: 200px;
                            min-width: 200px;
                            height: 100%;
                            // background-color: red;
                            margin-right: 30px;
                            cursor: pointer;

                            .itemImg {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height:60%;
                                width: 100%;

                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }

                            .itemName {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 20%;
                                font-weight: 500;
                            }

                            .itemPrice {
                                display: flex;
                                justify-content: center;
                                width: 100%;
                                height: 20%;
                                color: red;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

    }
}