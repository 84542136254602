// .dropdown {
//     position: relative;
//     .selected {
//         width: 100%;
//         height: 100%;
//         border: inherit;
//         border-radius: inherit;
//         background-color: inherit;
//         cursor: pointer;
//         outline: none;
//         box-sizing: border-box;
//     }

//     .optionItem {
//         width: 100%;
//         height: 100%;
//     }
// }


/* Dropdown.module.scss */
.dropdown {
    position: relative;
    // border: inherit;
    border-radius: inherit;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    // width: 200px;

    .selected {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px;
        // border: 1px solid #ccc;
        cursor: pointer;
        background-color: white;
        // border: inherit;
        border-radius: inherit;
        background-color: inherit;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        color: inherit;

        .arrow {
            position: absolute;
            right: 20px;
            color: inherit;
        }
    }
    .options {
        position: absolute;
        width: 100%;
        border: inherit;
        border-radius: inherit;
        background-color: inherit;
        max-height: 150px; /* 控制下拉選單最大高度 */
        overflow-y: auto;  /* 當選項過多時允許滾動 */
        z-index: 1;
        box-sizing: border-box;
        margin-top: 1px;
        border-width: 1px;
        border-style: solid;
        border-color: inherit;

        .optionItem {
            padding: 15px;  /* 調整選項高度 */
            cursor: pointer;

            &:hover {
                background-color: #f0f0f0;
            }
        }
    }

    .options::-webkit-scrollbar {
        width: 15px; /* 增加寬度以便我們能夠更明顯地看到效果 */
    }

    .options::-webkit-scrollbar-track {
        // background: #f1f1f1;
    }

    .options::-webkit-scrollbar-thumb {
        background-color: #bbb;
        border-radius: 10px;
        border: 5px solid transparent; /* 給滾動條兩邊加上透明的邊框 */
        background-clip: padding-box; /* 讓背景色只顯示在內部 */
    }
}