/* Marquee.css */
.marquee {
    display: flex;
    align-items: center;
    width: 300px;
    overflow: hidden;
    margin: 0 10px;
    height: 100%;
    // border: 1px solid #ccc;  // 可根据需要修改或删除边框
}

.marquee-content {
    display: inline-block;
    white-space: nowrap;
    padding-left: 100%;  // 开始时将文本移出视图
    animation: marquee-animation 40s linear infinite;    
    tab-size: 8;
    white-space: pre;
}

@keyframes marquee-animation {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);  // 横向移动整个容器宽度
    }
}