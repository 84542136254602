.main-title {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    min-width: 700px;
    box-sizing: border-box;
    padding: 0px 500px;
    // background-color: #555;
    background-color: #3D52A0;
    color: white;

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 10px;
        font-size: 20px;
        background-color: inherit;
        height: 50px;
        width: 150px;
        cursor: pointer;
        text-decoration: none;
        color: white;
    }

    .title:hover {
        transition: 0.20s;
        color: red;
        font-size: 25px;
        // filter: brightness(1.3)
    }
}