.main {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: black;
    box-sizing: border-box;
    padding: 0 2%;
    // font-family: "Montserrat", sans-serif;
    color: white;

    .logo {
        height: 50%;
        cursor: pointer;
    }

    .title {
        display: flex;
        align-items: center;
        margin-left: 10px;
        font-size: 1.5rem;
        width: 120px;
        height: 100%;
        cursor: pointer;
        font-family: 'Bungee Spice';
    }

    .titleList {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 40%;
        height: 100%;
        font-size: 1rem;

        .category {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15%;
            height: 100%;
            cursor: pointer;
        }

        .category:hover {
            transition: 0.25s;
            font-size: 1.3rem;
        }
    }

    .search {
        position: relative;
        display: flex;
        align-items: center;
        width: 30%;
        width: calc(100% - 725px);
        min-width: 345px;
        height: 100%;

        input[type="text"] {
            width: 100%;
            height: 55%;
            box-sizing: border-box;
            padding-right: 40px;
            padding-left: 10px;
            font-size: 20px
        }

        .search_icon {
            position: absolute;
            right: 20px;
            color: black;
        }
    }

    .rightItem {
        position: absolute;
        right: 0px;
        display: flex;
        width: 250px;
        height: 100%;

        .carshopping {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 100%;
            font-size: 1.5rem;
            cursor: pointer;
            text-decoration: none;
            color: white;

            .carShippingNumber {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 15px;
                right: 17px;
                font-size: 12px;
                background-color: red;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                box-sizing: border-box;
                pointer-events: none;
            }
        }

        .carshopping:hover {
            transition: 0.1s;
            font-size: 1.7rem;
        }

        .userProfile {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 110px;
            height: 100%;
            font-size: 1.5rem;
            cursor: pointer;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20%;
                height: 100%;
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 80%;
                height: 100%;

                .userName {
                    display: inline-block;
                    // justify-content: center;
                    // align-items: center;
                    width: 80%;
                    height: 20px;
                    font-size: 15px;
                    box-sizing: border-box;
                    text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
                    overflow: hidden;
                    padding-left: 10px;
                }
            }

            .text:hover {
                color: red;
            }
        }

        .userProfile:hover {
            transition: 0.1s;
            font-size: 1.7rem;
        }

        .global {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 100%;
            font-size: 1.5rem;
            cursor: pointer;

            img {
                height:40%;
            }

            .languageList {
                position: absolute;
                top: 60px;
                right: 20px;
                width: 110px;
                // height: 100px;
                background-color: black;
                color: black;
                z-index: 100;
                box-shadow: 0px 1px 8px 2px gray;
                color: white;
                font-size: 15px;
        
                .languageItem {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    box-sizing: border-box;
                    padding: 0 5%;
                    cursor: pointer;
        
                    .languageImg {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30%;
        
                        img {
                            width: 70%;
                        }
                    }
        
                    .languageDsc {
                        margin-left: 10px;
                    }
                }
        
                .languageItem:hover {
                    background-color: white;
                    color: black
                }
            }
        }

        .global:hover {
            transition: 0.1s;
            // font-size: 1.7rem;

            img {
                height: 45%;
            }
        }
    }
}